import Vue from 'vue';
import { preset } from 'vue-cli-plugin-vuetify-preset-rally/preset';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify)

export default new Vuetify({
  preset,
  theme: {
    options: {
      customProperties: true,
    },
    dark: false,
    themes: {
      dark: {
        primary: "#1EB980",
        secondary: "#045D56",
        tertiary: "#FF6859",
        quaternary: "#FFCF44",
        quinary: "#B15DFF",
        senary: "#72DEFF",
        accent: "#FF4081",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FB8C00",
        bigger: "#004f21",
        smaller: "#820000",
        background: "#33333d",
        hover: "#616161",
        text: "#ffffff",
        headertext: "#999999",
      },
      light: {
        // primary: "#337cb9",
        secondary: "#78828a",
        // accent: "#16b594",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FFC107",

        accent: "#337cb9",
        primary: "#16b594",

        // primary: "#1EB980",
        // secondary: "#045D56",
        tertiary: "#FF6859",
        quaternary: "#FFCF44",
        quinary: "#B15DFF",
        senary: "#72DEFF",
        // accent: "#FF4081",
        // error: "#FF5252",
        // info: "#2196F3",
        // success: "#4CAF50",
        // warning: "#FB8C00",

        bigger: "#004f21",
        smaller: "#820000",

        bgbigger: "#b9f8cf",
        bgsmaller: "#ffc9c9",

        progressbigger: "#05df72",
        progresssmaller: "#ff6467",

        // background: "#33333d",
        // hover: "#616161",
        // text: "#ffffff",
        // headertext: "#999999",

        background: "#f5f5f5",
        hover: "#bdbdbd",
        text: "#000000",
        headertext: "#212121",
      },
    },
  },
});
