import Vue from 'vue'
import Vuex from 'vuex'

import feathers from '@/feathers/'

import SERVER_URL from '@/config'

Vue.use(Vuex)

let workers = null
let queue = null

const next = commit => {
  if (queue.length) {
    const freeWorker = workers.find(worker => worker.free)

    if (freeWorker) {
      freeWorker.free = false
      const worker = freeWorker.worker
      const job = queue.shift()
      const { perioada, cif, indicator, index, trimestru, id, host, token, lang } = job
      let done = false

      const financialItems = structuredClone(financialItemsCollection[indicator])

      commit('setCompareProgress', { id, index, loading: true, completed: 0 })

      worker.onmessage = msg => {
        if (msg.data.type === 'progress' && !done) {
          commit('setCompareProgress', { id, index, loading: true, completed: msg.data.progress })
        } else if (msg.data.type === 'data') {
          done = true
          const financials = transformData(financialItems, msg.data.data.financials)
          commit('setCompareData', { id, index, indicator, data: { trimestru, data: msg.data.data.data, financials } })
          commit('setCompareProgress', { id, index, loading: false, completed: 100 })
          freeWorker.free = true
          next(commit)
        }
      }

      const coduri = []
      for (const item of financialItems.totaluri.venituri) {
        coduri.push({ ...item, key1: 'totaluri', key2: 'venituri' })
      }
      for (const item of financialItems.totaluri.cheltuieli) {
        coduri.push({ ...item, key1: 'totaluri', key2: 'cheltuieli' })
      }
      for (const item of financialItems.venituriLocale) {
        coduri.push({ ...item, key1: 'venituriLocale', key2: null })
      }
      for (const item of financialItems.cheltuieliFunctionale) {
        coduri.push({ ...item, key1: 'cheltuieliFunctionale', key2: null })
      }
      for (const item of financialItems.cheltuieliEconomice) {
        coduri.push({ ...item, key1: 'cheltuieliEconomice', key2: null })
      }

      worker.postMessage({
        host,
        token,
        service: 'cifuri',
        lang,
        cif,
        perioada,
        properties: ['_id', 'denumire_apl', 'judet', 'capita', 'tip_apl', 'cif_op', 'lat', 'long'],
        coduri
      })
    }
  }
}

export default new Vuex.Store({
  state: {
    host: SERVER_URL,
    feathers,
    services: {
      auth: feathers.service('authentication'),
      dictionary: feathers.service('dictionary'),
      dictionaryheaders: feathers.service('dictionaryheaders'),
      cifuri: feathers.service('cifuri'),
      perioade: feathers.service('cifuriperioade'),
      agregate: feathers.service('agregate')
    },
    auth: {
      user: null,
      token: null
    },
    dictionary: {
      languages: [],
      lang: localStorage.getItem('lang') || 'ro',
      ui: {}
    },
    activeCif: [],
    perioade: [],
    perioada: null,
    perioadaCompare: null,
    dnd: false,
    compare: false,
    aggregate: false,
    compareList: [],
    compareData: {},
    compareProgress: {},
    compareValueType: 'abs.',
    compareVisual: 'icon',
    labelWrapping: 'no-wrap',
    coloredTabled: 'no-color',
    indicatorCompare: 'Exe. La T',

    agregate: {
      items: [],
      search: null,
      page: 1,
      pages: 1
    }
  },
  getters: {
    user: state => state.auth.user,
    dictionaryLanguages: state => state.dictionary.languages,
    dictionaryLang: state => state.dictionary.lang,
    dictionaryUi: state => state.dictionary.ui,
    activeCif: state => state.activeCif,
    perioade: state => state.perioade,
    perioada: state => state.perioada,
    perioadaCompare: state => state.perioadaCompare,
    host: state => state.host,
    token: state => state.auth.token,
    dnd: state => state.dnd,
    compare: state => state.compare,
    aggregate: state => state.aggregate,
    compareList: state => state.compareList,
    compareData: state => state.compareData,
    compareValueType: state => state.compareValueType,
    compareProgress: state => state.compareProgress,
    financialItems: state => financialItemsCollection[state.indicatorCompare],
    compareVisual: state => state.compareVisual,
    labelWrapping: state => state.labelWrapping,
    coloredTabled: state => state.coloredTabled,
    indicatorCompare: state => state.indicatorCompare,
    agregateItems: state => state.agregate.items,
    agregateSearch: state => state.agregate.search,
    agregatePage: state => state.agregate.page,
    agregatePages: state => state.agregate.pages
  },
  mutations: {
    setAuth (state, payload) {
      Vue.set(state.auth, 'user', payload.user)
      Vue.set(state.auth, 'token', payload.accessToken)
    },
    setLanguages (state, payload) {
      Vue.set(state.dictionary, 'languages', payload)
    },
    setLang (state, payload) {
      Vue.set(state.dictionary, 'lang', payload)
      localStorage.setItem('lang', payload)
    },
    setDictionaryUI (state, payload) {
      const ui = {}

      if (Array.isArray(payload) && state.dictionary.languages.length) {
        payload.forEach(item => {
          const dictItem = {}
          state.dictionary.languages.forEach(lang => {
            dictItem[lang] = item[lang] || 'unavailable'
          })

          ui[item.cod] = dictItem
        })
      }

      Vue.set(state.dictionary, 'ui', ui)
    },

    setActiveCif (state, payload) {
      state.activeCif = payload
    },

    setPerioade (state, payload) {
      state.perioade = Object.freeze(structuredClone(payload))
    },
    setPerioada (state, payload) {
      state.perioada = Object.freeze(structuredClone(payload))
    },
    setDND (state, payload) {
      state.dnd = payload
    },
    toggleCompare (state) {
      state.compare = !state.compare

      if (state.compare) state.aggregate = false

      if (state.compare && !workers) {
        queue = []
        workers = new Array(navigator.hardwareConcurrency - 1 || 1).fill(null).map(() => {
          const worker = new Worker(new URL('@/workers/fetcher.worker.js', import.meta.url))

          return {
            worker,
            free: true
          }
        })
      }
    },
    toggleAgregate (state) {
      state.aggregate = !state.aggregate

      if (state.aggregate) state.compare = false
    },
    toggleDashboard (state) {
      state.aggregate = false
      state.compare = false
    },
    toggleCifInCompare (state, payload) {
      if (payload.add) {
        state.compareList.push({
          cif: payload.id,
          perioada: state.perioadaCompare,
          indicator: state.indicatorCompare,
          index: `${payload.id}-${state.perioadaCompare.label}-${state.indicatorCompare}`,
          percent: state.indicatorCompare.includes('Y2Y'),
          compareVisual: !!state.compareVisual
        })
      } else {
        state.compareList = state.compareList.filter(cif => cif.cif !== payload.id)
      }
    },
    setCompareData (state, payload) {
      const cifUsed = state.compareList.find(cif => cif.index === payload.index)
      if (cifUsed) {
        const compareData = structuredClone(state.compareData)
        compareData[payload.index] = payload.data

        state.compareData = Object.freeze(compareData)
      }
    },
    setCompareProgress (state, payload) {
      Vue.set(state.compareProgress, payload.index, { loading: payload.loading, completed: payload.completed })
    },
    removeCompareData (state, payload) {
      const newCompareData = {}
      Object.keys(state.compareData).forEach(key => {
        if (!key.startsWith(payload)) {
          newCompareData[key] = state.compareData[key]
        }
      })

      state.compareData = Object.freeze(newCompareData)
    },
    removeCompareProgress (state, payload) {
      const newCompareProgress = {}
      Object.keys(state.compareProgress).forEach(key => {
        if (!key.startsWith(payload)) {
          newCompareProgress[key] = state.compareProgress[key]
        }
      })

      state.compareProgress = newCompareProgress
    },

    setCompareValueType (state, payload) {
      state.compareValueType = payload
    },
    interchangeCompareListItems (state, payload) {
      const tempList = [...state.compareList]
      const aux = tempList[payload.posX]
      tempList[payload.posX] = tempList[payload.posY]
      tempList[payload.posY] = aux

      state.compareList = tempList
    },
    setCompareVisual (state, payload) {
      state.compareVisual = payload
    },

    setLabelWrapping (state, payload) {
      state.labelWrapping = payload
    },
    setColoredTabled (state, payload) {
      state.coloredTabled = payload
    },

    setPerioadaCompare (state, payload) {
      state.perioadaCompare = Object.freeze(structuredClone(payload))
    },
    clearCompareData (state) {
      state.compareData = {}
    },
    clearCompareProgress (state) {
      state.compareProgress = {}
    },
    setCompareList (state, payload) {
      state.compareList = payload.cifs.map(cif => {
        return {
          cif,
          index: `${cif}-${payload.perioada.label}-${payload.indicator}`,
          perioada: payload.perioada,
          indicator: payload.indicator,
          percent: payload.percent,
          compareVisual: payload.compareVisual
        }
      })
    },

    changeCifPerioada (state, payload) {
      // oldIndex = cif-oldPerioada
      // newIndex = cif-newPerioada
      // cifIndex = old array index in compareList; naming that prop index was not the best idea
      const oldIndex = payload.cif.index
      const newIndex = `${payload.cif.cif}-${payload.perioada.label}-${payload.cif.indicator}`
      const cifIndex = state.compareList.findIndex(cif => cif.index === oldIndex)

      const newCompareProgress = {}
      Object.keys(state.compareProgress).forEach(key => {
        if (key !== oldIndex) newCompareProgress[key] = state.compareProgress[key]
      })
      newCompareProgress[newIndex] = { loading: true, completed: 0 }

      const newCompareData = {}
      Object.keys(state.compareData).forEach(key => {
        if (key !== oldIndex) newCompareData[key] = state.compareData[key]
      })
      newCompareData[newIndex] = {
        index: newIndex,
        data: { trimestru: payload.perioada.label, data: null, financials: null }
      }

      state.compareProgress = newCompareProgress
      state.compareData = Object.freeze(newCompareData)

      const newCif = {
        cif: payload.cif.cif,
        perioada: payload.perioada,
        indicator: payload.cif.indicator,
        index: newIndex,
        percent: payload.cif.percent,
        compareVisual: payload.cif.compareVisual
      }
      Vue.set(state.compareList, cifIndex, newCif)
    },
    changeCifIndicator (state, payload) {
      // oldIndex = cif-oldPerioada
      // newIndex = cif-newPerioada
      // cifIndex = old array index in compareList; naming that prop index was not the best idea
      const oldIndex = payload.cif.index
      const newIndex = `${payload.cif.cif}-${payload.cif.perioada.label}-${payload.indicator}`
      const cifIndex = state.compareList.findIndex(cif => cif.index === oldIndex)

      const newCompareProgress = {}
      Object.keys(state.compareProgress).forEach(key => {
        if (key !== oldIndex) newCompareProgress[key] = state.compareProgress[key]
      })
      newCompareProgress[newIndex] = { loading: true, completed: 0 }

      const newCompareData = {}
      Object.keys(state.compareData).forEach(key => {
        if (key !== oldIndex) newCompareData[key] = state.compareData[key]
      })
      newCompareData[newIndex] = {
        index: newIndex,
        data: { trimestru: payload.cif.perioada.label, data: null, financials: null }
      }

      state.compareProgress = newCompareProgress
      state.compareData = Object.freeze(newCompareData)

      const newCif = {
        cif: payload.cif.cif,
        perioada: payload.cif.perioada,
        indicator: payload.indicator,
        index: newIndex,
        percent: payload.indicator.includes('Y2Y'),
        compareVisual: !payload.indicator.includes('Y2Y') && payload.cif.compareVisual
      }
      Vue.set(state.compareList, cifIndex, newCif)
    },
    duplicateCif (state, payload) {
      const { cif, aIndex, index, perioada, indicator } = payload

      state.compareList.splice(aIndex + 1, 0, {
        cif,
        perioada,
        indicator,
        index,
        percent: indicator.includes('Y2Y'),
        compareVisual: !!state.compareVisual
      })

      Vue.set(state.compareProgress, index, { loading: true, completed: 0 })

      const newCompareData = structuredClone(state.compareData)
      newCompareData[index] = {
        index,
        data: { trimestru: perioada.label, data: null, financials: null }
      }
      state.compareData = Object.freeze(newCompareData)
    },
    deleteCif (state, payload) {
      state.compareList.splice(payload.position, 1)

      const newCompareProgress = {}
      Object.keys(state.compareProgress).forEach(key => {
        if (key !== payload.cif.index) newCompareProgress[key] = state.compareProgress[key]
      })
      state.compareProgress = newCompareProgress

      const newCompareData = {}
      Object.keys(state.compareData).forEach(key => {
        if (key !== payload.cif.index) newCompareData[key] = state.compareData[key]
      })
      state.compareData = Object.freeze(newCompareData)
    },
    setIndicatorCompare (state, payload) {
      state.indicatorCompare = payload
    },

    toggleCifCompareVisual (state, payload) {
      const value = payload.cif.compareVisual
      state.compareList[payload.position].compareVisual = !value
    },
    toggleCifPercent (state, payload) {
      const value = payload.cif.percent
      state.compareList[payload.position].percent = !value
    },

    setAgregateItems (state, payload) {
      Vue.set(state.agregate, 'items', payload)
    },
    setAgregateSearch (state, payload) {
      Vue.set(state.agregate, 'search', payload)
    },
    setAgregatePage (state, payload) {
      Vue.set(state.agregate, 'page', payload)
    },
    setAgregatePages (state, payload) {
      Vue.set(state.agregate, 'pages', payload)
    }
  },
  actions: {
    // auth actions
    reAuth ({ state }) {
      return state.feathers.reAuthenticate()
    },
    async login ({ state, commit }, payload) {
      if (payload) {
        return state.feathers.authenticate({
          strategy: 'local',
          ...payload
        }).then(response => {
          commit('setAuth', response)
          return response
        }).catch(err => {
          console.log('store: ', err)
          return false
        })
      } else {
        return false
      }
    },
    async logout ({ state, commit }) {
      try {
        // logout will work and return null, but it will not throw in case of a bad token
        // hence the finally block
        await state.feathers.logout()
      } catch (err) {
        console.log(err)
      } finally {
        localStorage.removeItem('feathers-jwt')
        commit('setAuth', { user: null, accessToken: null })
      }
    },

    // ui actions
    async fetchLanguagesAndUI ({ state, dispatch }) {
      try {
        await dispatch('getLanguages')
        await dispatch('fetchUI')
      } catch (err) {
        if (err.code === 401) {
          dispatch('fetchLanguagesAndUI')
        } else if (err.message.startsWith('Invalid token specified')) {
          await dispatch('logout')
          localStorage.removeItem('feathers-jwt')
          location.reload()
        }
      }
    },

    async getLanguages ({ state, commit, dispatch }) {
      try {
        const res = await state.services.dictionaryheaders.find({ query: { $paginate: false } })

        const langs = res
          .filter(head => !['_id', 'createdAt', 'updatedAt', 'tab', 'cod'].includes(head.value) && head.value.length === 2)
          .map(head => head.value)
          .filter((head, index, arr) => index === arr.lastIndexOf(head))

        commit('setLanguages', langs)
      } catch (err) {
        console.log('Error fetching languages. ', err)
        throw err
      }
    },
    async fetchUI ({ state, commit, dispatch }) {
      try {
        const result = await state.services.dictionary.find({ query: { $paginate: false, tab: 'ui' } })
        commit('setDictionaryUI', result)
      } catch (err) {
        console.log('Error fetching UI. ', err)
        throw err
      }
    },

    // sidebar and dashboard actions
    async fetchCifuri ({ state, commit }) {
      const perioadeResult = await state.services.perioade.find({
        query: {
          $paginate: false,
          $sort: {
            date: -1
          },
          $select: ['_id', 'label']
        }
      })

      if (perioadeResult.length) {
        commit('setPerioade', perioadeResult)
        commit('setPerioada', perioadeResult[0])
        commit('setPerioadaCompare', perioadeResult[0])
        const cifuriResult = await state.services.cifuri.find({
          query: {
            $limit: 3228,
            $skip: 0,
            perioada: perioadeResult[0]._id,
            $select: ['_id', 'denumire_apl', 'judet', 'capita', 'tip_apl', 'cif_op']
          }
        })

        return cifuriResult.data
      } else {
        return []
      }
    },
    async fetchCifData ({ state }, payload) {
      const cifuriResult = await state.services.cifuri.find({
        query: {
          $limit: 1,
          $skip: 0,
          $select: ['_id', 'denumire_apl', 'judet', 'capita', 'tip_apl', 'cif_op', 'lat', 'long'],
          cif_op: payload._id,
          perioada: state.perioada._id
        }
      })

      return cifuriResult.data
    },
    async fetchCifFinancials ({ state }, payload) {
      return fetch(`${state.host}/interactive-reports-values`, {
        method: 'post',
        headers: new Headers({
          'Content-Type': 'application/json',
          Authorization: state.auth.token
        }),
        body: JSON.stringify({ items: payload, lang: state.dictionary.lang })
      })
    },
    async fetchCifReports ({ state }, payload) {
      return state.services.cifuri.find({
        query: {
          $aclReports: true,
          _id: payload,
          lang: state.dictionary.lang
        }
      })
    },

    // compare actions
    toggleCifInCompare ({ state, commit, dispatch }, payload) {
      const compareListCifs = Array.from(new Set(state.compareList.map(cif => cif.cif)))

      if (payload.length > compareListCifs.length) {
        // element added
        const addedCif = payload.find(cif => !compareListCifs.includes(cif))
        commit('toggleCifInCompare', { id: addedCif, add: true })
        commit('setCompareData', {
          index: `${addedCif}-${state.perioadaCompare.label}-${state.indicatorCompare}`,
          data: { trimestru: state.perioadaCompare.label, data: null, financials: null }
        })
        commit('setCompareProgress', { index: `${addedCif}-${state.perioadaCompare.label}-${state.indicatorCompare}`, loading: true, completed: 0 })
        dispatch('fetchCompareData', { id: addedCif, perioada: state.perioadaCompare, indicator: state.indicatorCompare })
      } else {
        // element removed
        const removedCifIndex = compareListCifs.findIndex(cif => !payload.includes(cif))
        commit('removeCompareData', compareListCifs[removedCifIndex])
        commit('removeCompareProgress', compareListCifs[removedCifIndex])
        commit('toggleCifInCompare', { id: compareListCifs[removedCifIndex], add: false })
      }
    },
    async fetchCompareData ({ state, commit }, payload) {
      const perioada = payload.perioada
      const cif = payload.id
      const indicator = payload.indicator
      const index = `${cif}-${perioada.label}-${indicator}`
      const trimestru = perioada.label.split('-').reverse().join('_')
      const id = payload.id

      queue.push({ perioada, cif, indicator, index, trimestru, id, host: state.host, token: state.auth.token, lang: state.lang })

      next(commit)
    },
    changePerioadaCompare ({ state, dispatch, commit }, payload) {
      commit('clearCompareData')
      commit('clearCompareProgress')
      commit('setPerioadaCompare', payload)

      const compareListCifs = Array.from(new Set(state.compareList.map(cif => cif.cif)))
      commit('setCompareList', { cifs: compareListCifs, perioada: payload, indicator: state.indicatorCompare, percent: state.indicatorCompare.includes('Y2Y'), compareVisual: !!state.compareVisual })

      state.compareList.forEach(cif => {
        const index = `${cif.cif}-${payload.label}-${state.indicatorCompare}`
        commit('setCompareData', { id: cif.cif, index, data: { trimestru: payload.label, data: null, financials: null } })
        commit('setCompareProgress', { id: cif.cif, index, loading: true, completed: 0 })
        dispatch('fetchCompareData', { id: cif.cif, perioada: payload, indicator: state.indicatorCompare })
      })
    },
    changeIndicatorCompare ({ state, dispatch, commit }, payload) {
      commit('clearCompareData')
      commit('clearCompareProgress')
      commit('setIndicatorCompare', payload)

      const compareListCifs = Array.from(new Set(state.compareList.map(cif => cif.cif)))
      commit('setCompareList', { cifs: compareListCifs, perioada: state.perioadaCompare, indicator: payload, percent: payload.includes('Y2Y'), compareVisual: !!state.compareVisual })

      state.compareList.forEach(cif => {
        const index = `${cif.cif}-${state.perioadaCompare.label}-${payload}`
        commit('setCompareData', { id: cif.cif, index, data: { trimestru: state.perioadaCompare.label, data: null, financials: null } })
        commit('setCompareProgress', { id: cif.cif, index, loading: true, completed: 0 })
        dispatch('fetchCompareData', { id: cif.cif, perioada: state.perioadaCompare, indicator: payload })
      })
    },
    changeCifPerioada ({ dispatch, commit }, payload) {
      commit('changeCifPerioada', payload)
      dispatch('fetchCompareData', { id: payload.cif.cif, perioada: payload.perioada, indicator: payload.cif.indicator })
    },
    changeCifIndicator ({ dispatch, commit }, payload) {
      commit('changeCifIndicator', payload)
      dispatch('fetchCompareData', { id: payload.cif.cif, perioada: payload.cif.perioada, indicator: payload.indicator })
    },
    duplicateCif ({ state, dispatch, commit }, payload) {
      // find first unused perioada for cif
      const usedPerioade = state.compareList.filter(cif => cif.cif === payload.cif.cif).map(cif => cif.perioada.label)
      const perioada = state.perioade.find(p => !usedPerioade.includes(p.label))

      const index = perioada ? `${payload.cif.cif}-${perioada.label}-${state.indicatorCompare}` : null

      if (index && perioada) {
        commit('duplicateCif', { cif: payload.cif.cif, aIndex: payload.position, index, perioada, indicator: state.indicatorCompare })
        dispatch('fetchCompareData', { id: payload.cif.cif, perioada, indicator: state.indicatorCompare })
      }
    },

    async fetchAgregate ({ state, commit }) {
      const query = {
        $complexFacet: true,
        $search: state.agregate.search,
        $limit: 10,
        $skip: (state.agregate.page - 1) * 10
      }

      const result = await state.services.agregate.find({ query })

      commit('setAgregatePages', Math.ceil((result.total || 0) / 10))
      commit('setAgregateItems', result.data)
    }
  }
})

const transformData = (obj, data) => {
  const financials = structuredClone(obj)
  for (const item of financials.totaluri.venituri) {
    const found = data.find(i => i.key1 === 'totaluri' && i.key2 === 'venituri' && i.code === item.code)
    item.value = found.value
    item.formattedValue = found.formattedValue
  }
  for (const item of financials.totaluri.cheltuieli) {
    const found = data.find(i => i.key1 === 'totaluri' && i.key2 === 'cheltuieli' && i.code === item.code)
    item.value = found.value
    item.formattedValue = found.formattedValue
  }
  for (const item of financials.venituriLocale) {
    const found = data.find(i => i.key1 === 'venituriLocale' && i.code === item.code)
    item.value = found.value
    item.formattedValue = found.formattedValue
  }
  for (const item of financials.cheltuieliFunctionale) {
    const found = data.find(i => i.key1 === 'cheltuieliFunctionale' && i.code === item.code)
    item.value = found.value
    item.formattedValue = found.formattedValue
  }
  for (const item of financials.cheltuieliEconomice) {
    const found = data.find(i => i.key1 === 'cheltuieliEconomice' && i.code === item.code)
    item.value = found.value
    item.formattedValue = found.formattedValue
  }

  return financials
}

const financialItemsCollection = Object.freeze({
  'Exe. La T': {
    totaluri: {
      venituri: [
        { id: 1, label: 'Total venituri', value: null, formattedValue: null, code: 'C!Ven_cSet1_c0001_A_ExeLa_$trimestru_$cif' },
        { id: 2, label: 'Venituri proprii', value: null, formattedValue: null, code: 'I!_0a00_inteprexela_$trimestru_$cif' }
      ],
      cheltuieli: [
        { id: 3, label: 'Total cheltuieli', value: null, formattedValue: null, code: 'C!Fct_cSet1_c0000_A_ExeLa_$trimestru_$cif' },
        { id: 4, label: 'Cheltuieli Investitii', value: null, formattedValue: null, code: 'I!_B08_chstdla_$trimestru_$cif' }
      ]
    },
    venituriLocale: [
      { id: 5, label: 'Total venituri', value: null, formattedValue: null, code: 'I!_000_inteprexela_$trimestru_$cif' },
      { id: 6, label: 'Venituri proprii', value: null, formattedValue: null, code: 'I!_0a00_inteprexela_$trimestru_$cif' },
      { id: 7, label: 'Sume defalcate din taxa pe valoare adaugata', value: null, formattedValue: null, code: 'I!_0b00_inteprexela_$trimestru_$cif' },
      { id: 8, label: 'Donatii si sponsorizari', value: null, formattedValue: null, code: 'I!_0c00_inteprexela_$trimestru_$cif' },
      { id: 9, label: 'Subventii de la bugetul de stat', value: null, formattedValue: null, code: 'I!_0d00_inteprexela_$trimestru_$cif' },
      { id: 10, label: 'Sume primite de la UE în contul platilor efectuate', value: null, formattedValue: null, code: 'I!_0e00_inteprexela_$trimestru_$cif' },
      { id: 11, label: 'Alte venituri', value: null, formattedValue: null, code: 'I!_0e01_inteprexela_$trimestru_$cif' }
    ],
    cheltuieliFunctionale: [
      { id: 12, label: 'Autoritati publice si actiuni externe', value: null, formattedValue: null, code: 'I!_1a01_inteprexela_$trimestru_$cif' },
      { id: 13, label: 'Tranzactii privind datoria publica si imprumuturi', value: null, formattedValue: null, code: 'I!_1a02_inteprexela_$trimestru_$cif' },
      { id: 14, label: 'Invatamant', value: null, formattedValue: null, code: 'I!_1a03_inteprexela_$trimestru_$cif' },
      { id: 15, label: 'Sanatate', value: null, formattedValue: null, code: 'I!_1a04_inteprexela_$trimestru_$cif' },
      { id: 16, label: 'Cultura, recreere si religie', value: null, formattedValue: null, code: 'I!_1a05_inteprexela_$trimestru_$cif' },
      { id: 17, label: 'Asigurari si asistenta sociala', value: null, formattedValue: null, code: 'I!_1a06_inteprexela_$trimestru_$cif' },
      { id: 18, label: 'Locuinte, servicii si dezvoltare publica', value: null, formattedValue: null, code: 'I!_1a07_inteprexela_$trimestru_$cif' },
      { id: 19, label: 'Protectia mediului', value: null, formattedValue: null, code: 'I!_1a08_inteprexela_$trimestru_$cif' },
      { id: 20, label: 'Transporturi', value: null, formattedValue: null, code: 'I!_1a09_inteprexela_$trimestru_$cif' },
      { id: 21, label: 'Alte cheltuieli', value: null, formattedValue: null, code: 'I!_1a10_inteprexela_$trimestru_$cif' }
    ],
    cheltuieliEconomice: [
      { id: 22, label: 'Cheltuieli de personal', value: null, formattedValue: null, code: 'I!_1b01_inteprexela_$trimestru_$cif' },
      { id: 23, label: 'Asistenta sociala', value: null, formattedValue: null, code: 'I!_1b02_inteprexela_$trimestru_$cif' },
      { id: 24, label: 'Subventii', value: null, formattedValue: null, code: 'I!_1b03_inteprexela_$trimestru_$cif' },
      { id: 25, label: 'Bunuri si servicii', value: null, formattedValue: null, code: 'I!_1b04_inteprexela_$trimestru_$cif' },
      { id: 26, label: 'Cheltuieli de capital', value: null, formattedValue: null, code: 'I!_1b05_inteprexela_$trimestru_$cif' },
      { id: 27, label: 'Dobanzi', value: null, formattedValue: null, code: 'I!_1b06_inteprexela_$trimestru_$cif' },
      { id: 28, label: 'Rambursari de credite', value: null, formattedValue: null, code: 'I!_1b07_inteprexela_$trimestru_$cif' },
      { id: 29, label: 'Transferuri intre unitati ale administratiei publice', value: null, formattedValue: null, code: 'I!_1b08_inteprexela_$trimestru_$cif' },
      { id: 30, label: 'Alte transferuri', value: null, formattedValue: null, code: 'I!_1b09_inteprexela_$trimestru_$cif' },
      { id: 31, label: 'Proiecte cu finantare din FEN', value: null, formattedValue: null, code: 'I!_1b10_inteprexela_$trimestru_$cif' },
      { id: 32, label: 'Plati efectuate in anii precedenti si recuperate in anul curent', value: null, formattedValue: null, code: 'I!_1b11_inteprexela_$trimestru_$cif' },
      { id: 33, label: 'Alte cheltuieli', value: null, formattedValue: null, code: 'I!_1b12_inteprexela_$trimestru_$cif' }
    ]
  },
  'Exe. In T': {
    totaluri: {
      venituri: [
        { id: 1, label: 'Total venituri', value: null, formattedValue: null, code: 'C!Ven_cSet1_c0001_A_ExeIn_$trimestru_$cif' },
        { id: 2, label: 'Venituri proprii', value: null, formattedValue: null, code: 'I!_0a00_inteprexein_$trimestru_$cif' }
      ],
      cheltuieli: [
        { id: 3, label: 'Total cheltuieli', value: null, formattedValue: null, code: 'C!Fct_cSet1_c0000_A_ExeIn_$trimestru_$cif' },
        { id: 4, label: 'Cheltuieli Investitii', value: null, formattedValue: null, code: 'I!_B08_chstdin_$trimestru_$cif' }
      ]
    },
    venituriLocale: [
      { id: 5, label: 'Total venituri', value: null, formattedValue: null, code: 'I!_000_inteprexein_$trimestru_$cif' },
      { id: 6, label: 'Venituri proprii', value: null, formattedValue: null, code: 'I!_0a00_inteprexein_$trimestru_$cif' },
      { id: 7, label: 'Sume defalcate din taxa pe valoare adaugata', value: null, formattedValue: null, code: 'I!_0b00_inteprexein_$trimestru_$cif' },
      { id: 8, label: 'Donatii si sponsorizari', value: null, formattedValue: null, code: 'I!_0c00_inteprexein_$trimestru_$cif' },
      { id: 9, label: 'Subventii de la bugetul de stat', value: null, formattedValue: null, code: 'I!_0d00_inteprexein_$trimestru_$cif' },
      { id: 10, label: 'Sume primite de la UE în contul platilor efectuate', value: null, formattedValue: null, code: 'I!_0e00_inteprexein_$trimestru_$cif' },
      { id: 11, label: 'Alte venituri', value: null, formattedValue: null, code: 'I!_0e01_inteprexein_$trimestru_$cif' }
    ],
    cheltuieliFunctionale: [
      { id: 12, label: 'Autoritati publice si actiuni externe', value: null, formattedValue: null, code: 'I!_1a01_inteprexein_$trimestru_$cif' },
      { id: 13, label: 'Tranzactii privind datoria publica si imprumuturi', value: null, formattedValue: null, code: 'I!_1a02_inteprexein_$trimestru_$cif' },
      { id: 14, label: 'Invatamant', value: null, formattedValue: null, code: 'I!_1a03_inteprexein_$trimestru_$cif' },
      { id: 15, label: 'Sanatate', value: null, formattedValue: null, code: 'I!_1a04_inteprexein_$trimestru_$cif' },
      { id: 16, label: 'Cultura, recreere si religie', value: null, formattedValue: null, code: 'I!_1a05_inteprexein_$trimestru_$cif' },
      { id: 17, label: 'Asigurari si asistenta sociala', value: null, formattedValue: null, code: 'I!_1a06_inteprexein_$trimestru_$cif' },
      { id: 18, label: 'Locuinte, servicii si dezvoltare publica', value: null, formattedValue: null, code: 'I!_1a07_inteprexein_$trimestru_$cif' },
      { id: 19, label: 'Protectia mediului', value: null, formattedValue: null, code: 'I!_1a08_inteprexein_$trimestru_$cif' },
      { id: 20, label: 'Transporturi', value: null, formattedValue: null, code: 'I!_1a09_inteprexein_$trimestru_$cif' },
      { id: 21, label: 'Alte cheltuieli', value: null, formattedValue: null, code: 'I!_1a10_inteprexein_$trimestru_$cif' }
    ],
    cheltuieliEconomice: [
      { id: 22, label: 'Cheltuieli de personal', value: null, formattedValue: null, code: 'I!_1b01_inteprexein_$trimestru_$cif' },
      { id: 23, label: 'Asistenta sociala', value: null, formattedValue: null, code: 'I!_1b02_inteprexein_$trimestru_$cif' },
      { id: 24, label: 'Subventii', value: null, formattedValue: null, code: 'I!_1b03_inteprexein_$trimestru_$cif' },
      { id: 25, label: 'Bunuri si servicii', value: null, formattedValue: null, code: 'I!_1b04_inteprexein_$trimestru_$cif' },
      { id: 26, label: 'Cheltuieli de capital', value: null, formattedValue: null, code: 'I!_1b05_inteprexein_$trimestru_$cif' },
      { id: 27, label: 'Dobanzi', value: null, formattedValue: null, code: 'I!_1b06_inteprexein_$trimestru_$cif' },
      { id: 28, label: 'Rambursari de credite', value: null, formattedValue: null, code: 'I!_1b07_inteprexein_$trimestru_$cif' },
      { id: 29, label: 'Transferuri intre unitati ale administratiei publice', value: null, formattedValue: null, code: 'I!_1b08_inteprexein_$trimestru_$cif' },
      { id: 30, label: 'Alte transferuri', value: null, formattedValue: null, code: 'I!_1b09_inteprexein_$trimestru_$cif' },
      { id: 31, label: 'Proiecte cu finantare din FEN', value: null, formattedValue: null, code: 'I!_1b10_inteprexein_$trimestru_$cif' },
      { id: 32, label: 'Plati efectuate in anii precedenti si recuperate in anul curent', value: null, formattedValue: null, code: 'I!_1b11_inteprexein_$trimestru_$cif' },
      { id: 33, label: 'Alte cheltuieli', value: null, formattedValue: null, code: 'I!_1b12_inteprexein_$trimestru_$cif' }
    ]
  },
  'Y2Y La T': {
    totaluri: {
      venituri: [
        { id: 1, label: 'Total venituri', value: null, formattedValue: null, code: 'C!Ven_cSet1_c0001_A_Y2YLa_$trimestru_$cif' },
        { id: 2, label: 'Venituri proprii', value: null, formattedValue: null, code: 'I!_0a00_intepry2yla_$trimestru_$cif' }
      ],
      cheltuieli: [
        { id: 3, label: 'Total cheltuieli', value: null, formattedValue: null, code: 'C!Fct_cSet1_c0000_A_Y2YLa_$trimestru_$cif' },
        { id: 4, label: 'Cheltuieli Investitii', value: null, formattedValue: null, code: 'I!_B08_chstdy2yla_$trimestru_$cif' }
      ]
    },
    venituriLocale: [
      { id: 5, label: 'Total venituri', value: null, formattedValue: null, code: 'I!_000_intepry2yla_$trimestru_$cif' },
      { id: 6, label: 'Venituri proprii', value: null, formattedValue: null, code: 'I!_0a00_intepry2yla_$trimestru_$cif' },
      { id: 7, label: 'Sume defalcate din taxa pe valoare adaugata', value: null, formattedValue: null, code: 'I!_0b00_intepry2yla_$trimestru_$cif' },
      { id: 8, label: 'Donatii si sponsorizari', value: null, formattedValue: null, code: 'I!_0c00_intepry2yla_$trimestru_$cif' },
      { id: 9, label: 'Subventii de la bugetul de stat', value: null, formattedValue: null, code: 'I!_0d00_intepry2yla_$trimestru_$cif' },
      { id: 10, label: 'Sume primite de la UE în contul platilor efectuate', value: null, formattedValue: null, code: 'I!_0e00_intepry2yla_$trimestru_$cif' },
      { id: 11, label: 'Alte venituri', value: null, formattedValue: null, code: 'I!_0e01_intepry2yla_$trimestru_$cif' }
    ],
    cheltuieliFunctionale: [
      { id: 12, label: 'Autoritati publice si actiuni externe', value: null, formattedValue: null, code: 'I!_1a01_intepry2yla_$trimestru_$cif' },
      { id: 13, label: 'Tranzactii privind datoria publica si imprumuturi', value: null, formattedValue: null, code: 'I!_1a02_intepry2yla_$trimestru_$cif' },
      { id: 14, label: 'Invatamant', value: null, formattedValue: null, code: 'I!_1a03_intepry2yla_$trimestru_$cif' },
      { id: 15, label: 'Sanatate', value: null, formattedValue: null, code: 'I!_1a04_intepry2yla_$trimestru_$cif' },
      { id: 16, label: 'Cultura, recreere si religie', value: null, formattedValue: null, code: 'I!_1a05_intepry2yla_$trimestru_$cif' },
      { id: 17, label: 'Asigurari si asistenta sociala', value: null, formattedValue: null, code: 'I!_1a06_intepry2yla_$trimestru_$cif' },
      { id: 18, label: 'Locuinte, servicii si dezvoltare publica', value: null, formattedValue: null, code: 'I!_1a07_intepry2yla_$trimestru_$cif' },
      { id: 19, label: 'Protectia mediului', value: null, formattedValue: null, code: 'I!_1a08_intepry2yla_$trimestru_$cif' },
      { id: 20, label: 'Transporturi', value: null, formattedValue: null, code: 'I!_1a09_intepry2yla_$trimestru_$cif' },
      { id: 21, label: 'Alte cheltuieli', value: null, formattedValue: null, code: 'I!_1a10_intepry2yla_$trimestru_$cif' }
    ],
    cheltuieliEconomice: [
      { id: 22, label: 'Cheltuieli de personal', value: null, formattedValue: null, code: 'I!_1b01_intepry2yla_$trimestru_$cif' },
      { id: 23, label: 'Asistenta sociala', value: null, formattedValue: null, code: 'I!_1b02_intepry2yla_$trimestru_$cif' },
      { id: 24, label: 'Subventii', value: null, formattedValue: null, code: 'I!_1b03_intepry2yla_$trimestru_$cif' },
      { id: 25, label: 'Bunuri si servicii', value: null, formattedValue: null, code: 'I!_1b04_intepry2yla_$trimestru_$cif' },
      { id: 26, label: 'Cheltuieli de capital', value: null, formattedValue: null, code: 'I!_1b05_intepry2yla_$trimestru_$cif' },
      { id: 27, label: 'Dobanzi', value: null, formattedValue: null, code: 'I!_1b06_intepry2yla_$trimestru_$cif' },
      { id: 28, label: 'Rambursari de credite', value: null, formattedValue: null, code: 'I!_1b07_intepry2yla_$trimestru_$cif' },
      { id: 29, label: 'Transferuri intre unitati ale administratiei publice', value: null, formattedValue: null, code: 'I!_1b08_intepry2yla_$trimestru_$cif' },
      { id: 30, label: 'Alte transferuri', value: null, formattedValue: null, code: 'I!_1b09_intepry2yla_$trimestru_$cif' },
      { id: 31, label: 'Proiecte cu finantare din FEN', value: null, formattedValue: null, code: 'I!_1b10_intepry2yla_$trimestru_$cif' },
      { id: 32, label: 'Plati efectuate in anii precedenti si recuperate in anul curent', value: null, formattedValue: null, code: 'I!_1b11_intepry2yla_$trimestru_$cif' },
      { id: 33, label: 'Alte cheltuieli', value: null, formattedValue: null, code: 'I!_1b12_intepry2yla_$trimestru_$cif' }
    ]
  },
  'Y2Y In T': {
    totaluri: {
      venituri: [
        { id: 1, label: 'Total venituri', value: null, formattedValue: null, code: 'C!Ven_cSet1_c0001_A_Y2YIn_$trimestru_$cif' },
        { id: 2, label: 'Venituri proprii', value: null, formattedValue: null, code: 'I!_0a00_intepry2yin_$trimestru_$cif' }
      ],
      cheltuieli: [
        { id: 3, label: 'Total cheltuieli', value: null, formattedValue: null, code: 'C!Fct_cSet1_c0000_A_Y2YIn_$trimestru_$cif' },
        { id: 4, label: 'Cheltuieli Investitii', value: null, formattedValue: null, code: 'I!_B08_chstdy2yin_$trimestru_$cif' }
      ]
    },
    venituriLocale: [
      { id: 5, label: 'Total venituri', value: null, formattedValue: null, code: 'I!_000_intepry2yin_$trimestru_$cif' },
      { id: 6, label: 'Venituri proprii', value: null, formattedValue: null, code: 'I!_0a00_intepry2yin_$trimestru_$cif' },
      { id: 7, label: 'Sume defalcate din taxa pe valoare adaugata', value: null, formattedValue: null, code: 'I!_0b00_intepry2yin_$trimestru_$cif' },
      { id: 8, label: 'Donatii si sponsorizari', value: null, formattedValue: null, code: 'I!_0c00_intepry2yin_$trimestru_$cif' },
      { id: 9, label: 'Subventii de la bugetul de stat', value: null, formattedValue: null, code: 'I!_0d00_intepry2yin_$trimestru_$cif' },
      { id: 10, label: 'Sume primite de la UE în contul platilor efectuate', value: null, formattedValue: null, code: 'I!_0e00_intepry2yin_$trimestru_$cif' },
      { id: 11, label: 'Alte venituri', value: null, formattedValue: null, code: 'I!_0e01_intepry2yin_$trimestru_$cif' }
    ],
    cheltuieliFunctionale: [
      { id: 12, label: 'Autoritati publice si actiuni externe', value: null, formattedValue: null, code: 'I!_1a01_intepry2yin_$trimestru_$cif' },
      { id: 13, label: 'Tranzactii privind datoria publica si imprumuturi', value: null, formattedValue: null, code: 'I!_1a02_intepry2yin_$trimestru_$cif' },
      { id: 14, label: 'Invatamant', value: null, formattedValue: null, code: 'I!_1a03_intepry2yin_$trimestru_$cif' },
      { id: 15, label: 'Sanatate', value: null, formattedValue: null, code: 'I!_1a04_intepry2yin_$trimestru_$cif' },
      { id: 16, label: 'Cultura, recreere si religie', value: null, formattedValue: null, code: 'I!_1a05_intepry2yin_$trimestru_$cif' },
      { id: 17, label: 'Asigurari si asistenta sociala', value: null, formattedValue: null, code: 'I!_1a06_intepry2yin_$trimestru_$cif' },
      { id: 18, label: 'Locuinte, servicii si dezvoltare publica', value: null, formattedValue: null, code: 'I!_1a07_intepry2yin_$trimestru_$cif' },
      { id: 19, label: 'Protectia mediului', value: null, formattedValue: null, code: 'I!_1a08_intepry2yin_$trimestru_$cif' },
      { id: 20, label: 'Transporturi', value: null, formattedValue: null, code: 'I!_1a09_intepry2yin_$trimestru_$cif' },
      { id: 21, label: 'Alte cheltuieli', value: null, formattedValue: null, code: 'I!_1a10_intepry2yin_$trimestru_$cif' }
    ],
    cheltuieliEconomice: [
      { id: 22, label: 'Cheltuieli de personal', value: null, formattedValue: null, code: 'I!_1b01_intepry2yin_$trimestru_$cif' },
      { id: 23, label: 'Asistenta sociala', value: null, formattedValue: null, code: 'I!_1b02_intepry2yin_$trimestru_$cif' },
      { id: 24, label: 'Subventii', value: null, formattedValue: null, code: 'I!_1b03_intepry2yin_$trimestru_$cif' },
      { id: 25, label: 'Bunuri si servicii', value: null, formattedValue: null, code: 'I!_1b04_intepry2yin_$trimestru_$cif' },
      { id: 26, label: 'Cheltuieli de capital', value: null, formattedValue: null, code: 'I!_1b05_intepry2yin_$trimestru_$cif' },
      { id: 27, label: 'Dobanzi', value: null, formattedValue: null, code: 'I!_1b06_intepry2yin_$trimestru_$cif' },
      { id: 28, label: 'Rambursari de credite', value: null, formattedValue: null, code: 'I!_1b07_intepry2yin_$trimestru_$cif' },
      { id: 29, label: 'Transferuri intre unitati ale administratiei publice', value: null, formattedValue: null, code: 'I!_1b08_intepry2yin_$trimestru_$cif' },
      { id: 30, label: 'Alte transferuri', value: null, formattedValue: null, code: 'I!_1b09_intepry2yin_$trimestru_$cif' },
      { id: 31, label: 'Proiecte cu finantare din FEN', value: null, formattedValue: null, code: 'I!_1b10_intepry2yin_$trimestru_$cif' },
      { id: 32, label: 'Plati efectuate in anii precedenti si recuperate in anul curent', value: null, formattedValue: null, code: 'I!_1b11_intepry2yin_$trimestru_$cif' },
      { id: 33, label: 'Alte cheltuieli', value: null, formattedValue: null, code: 'I!_1b12_intepry2yin_$trimestru_$cif' }
    ]
  }
})
