<template>
  <v-container fluid class="fill-height" style="background-size: cover; width: 500px; max-width: 90vw">
    <v-row class="justify-center align-center">
      <v-col cols="12">
        <v-card class="ob-login elevation-10" outlined shaped>
          <v-card-title>
            <v-spacer></v-spacer>
            <h2 class="text-center primary--text text--darken-2 display-1 font-weight-light" v-html="translateUI('Login_1', lang)">
            </h2>
            <v-spacer></v-spacer>
            <v-btn icon color="error" text @click="emitEvent('close-login')">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-form>
              <v-row class="justify-center">
                <v-col cols="12">
                  <v-text-field
                    light
                    type="email"
                    prepend-icon="mdi-account-circle"
                    filled rounded
                    hide-details
                    :placeholder="translateUI('Login_2', lang)"
                    v-model="email"
                    @keyup.enter="login">
                  </v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    light
                    :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="showPass = !showPass"
                    :type="showPass ? 'text' : 'password'"
                    prepend-icon="mdi-lock"
                    filled rounded
                    hide-details
                    :placeholder="translateUI('Login_3', lang)"
                    v-model="password"
                    @keyup.enter="login">
                  </v-text-field>
                </v-col>
                <v-col cols="12" v-if="error">
                  <v-alert :value="!!error" type="error">
                    {{ translateUI('Login_4', lang) }}
                  </v-alert>
                </v-col>
              </v-row>
            </v-form>
            <div class="text-center mt-4">
              <v-btn color="primary" large :disabled="!email || !password || loading" :loading="loading" @click="login" light>
                <v-icon>mdi-login-variant</v-icon>
              </v-btn>
            </div>
            <div class="text-center mt-4">
              <a href="mailto:analiza@tudconsult.ro" target="_blank" class="ma-0 secondary--text text--lighten-1 ob-login-links">
                <v-icon small class="secondary--text text--lighten-1 mr-1">mdi-email</v-icon>
                Contact
              </a>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import TranslateMixin from '@/mixins/Translate'

export default {
  name: 'LoginComponent',
  mixins: [TranslateMixin],
  data: () => ({
    email: '',
    password: '',
    error: null,
    showPass: false,
    loading: false
  }),
  computed: {
    lang () {
      return this.$store.getters.dictionaryLang
    }
  },
  methods: {
    emitEvent (event) {
      this.$emit(event)
    },
    login () {
      if (this.email && this.password) {
        this.error = null
        this.loading = true
        this.$store.dispatch('login', { email: this.email.trim(), password: this.password.trim() })
          .then(result => {
            const user = result.user
            if (user) {
              this.error = null
              this.email = null
              this.password = null
              this.$router.push({ name: 'Home' })
            } else {
              this.error = 'Combinatie incorecta de email/parola...'
            }
          }).catch(error => {
            console.log(error)
            this.error = 'Combinatie incorecta de email/parola...'
          }).finally(() => {
            this.loading = false
          })
      }
    }
  }
}
</script>
